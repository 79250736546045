import { default as adminAOa9i81ClJMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as confirm487kH8ZehdMeta } from "/opt/build/repo/pages/confirm.vue?macro=true";
import { default as galleryJF0O2yKYYdMeta } from "/opt/build/repo/pages/gallery.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as uploadsvejL9QwXx5Meta } from "/opt/build/repo/pages/uploads.vue?macro=true";
export default [
  {
    name: adminAOa9i81ClJMeta?.name ?? "admin",
    path: adminAOa9i81ClJMeta?.path ?? "/admin",
    meta: adminAOa9i81ClJMeta || {},
    alias: adminAOa9i81ClJMeta?.alias || [],
    redirect: adminAOa9i81ClJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: confirm487kH8ZehdMeta?.name ?? "confirm",
    path: confirm487kH8ZehdMeta?.path ?? "/confirm",
    meta: confirm487kH8ZehdMeta || {},
    alias: confirm487kH8ZehdMeta?.alias || [],
    redirect: confirm487kH8ZehdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: galleryJF0O2yKYYdMeta?.name ?? "gallery",
    path: galleryJF0O2yKYYdMeta?.path ?? "/gallery",
    meta: galleryJF0O2yKYYdMeta || {},
    alias: galleryJF0O2yKYYdMeta?.alias || [],
    redirect: galleryJF0O2yKYYdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: uploadsvejL9QwXx5Meta?.name ?? "uploads",
    path: uploadsvejL9QwXx5Meta?.path ?? "/uploads",
    meta: uploadsvejL9QwXx5Meta || {},
    alias: uploadsvejL9QwXx5Meta?.alias || [],
    redirect: uploadsvejL9QwXx5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/uploads.vue").then(m => m.default || m)
  }
]